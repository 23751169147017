<template>
  <v-card elevation="2" outlined class="mx-auto">
    <v-card-actions class="col-xs-12 d-sm-flex flex-wrap">
      <v-btn v-if="role" color="primary" class="darken-1 mx-2" dense @click="showUploadInvoiceModal"
        ><span class="text-capitalize">{{ labels.BTN_INVOICE_DETAILS_SHINKI }}</span>
      </v-btn>
      <v-btn v-if="role" color="success" class="mx-2" dense :disabled="!invoiceId" @click="downloadRemakeCSV"
        >{{ labels.BTN_INVOICE_REMAKE }}
      </v-btn>

      <v-autocomplete
        class="ma-2 my-sm-0"
        style="max-width: 280px"
        color="primary"
        v-model="selectedInvoice"
        :label="konpoMeisai.inputs.invoiceBango.label"
        :items="invoices"
        item-text="matterNo"
        item-value="value"
        outlined
        clearable
        dense
        hide-details
        @change="getInvoiceDetails"
        @click:clear="resetTables"
      >
      </v-autocomplete>

      <v-checkbox
        class="mx-2"
        v-model="hakomatomezumi"
        :label="konpoMeisai.inputs.hakomatomezumi.label"
        hide-details
        :disabled="recoverInvoices"
        dense
      ></v-checkbox>
      <v-checkbox
        v-if="user.authority === 9"
        class="mx-2"
        v-model="recoverInvoices"
        :label="konpoMeisai.inputs.recoverInvoices.label"
        hide-details
        dense
      ></v-checkbox>
      <v-spacer></v-spacer>

      <v-btn
        v-if="role"
        class="mr-3"
        color="red accent-4 white--text"
        :disabled="invoiceStatus > 4"
        @click="showDangerModal = true"
      >
        {{ labels.BTN_ANKEN_CANCEL }}
      </v-btn>
    </v-card-actions>

    <v-row justify="space-between mx-2 my-0">
      <v-card-subtitle class="d-inline-flex align-end pa-1"
        ><v-icon v-if="konpoMeisai.inputs.buyerName.icon" class="mr-1" color="primary">{{
          konpoMeisai.inputs.buyerName.icon
        }}</v-icon>
        <span style="font-size: x-large">{{ konpoMeisai.inputs.buyerName.label }}：</span>
        <span style="font-size: x-large">{{ buyerName }}</span>
      </v-card-subtitle>

      <v-card-actions class="d-sm-flex flex-wrap justify-center">
        <v-btn
          v-if="role"
          color="primary"
          :loading="loading"
          :disabled="disableAutoConsolidateBtn"
          @click="autoConsolidateBoxes"
        >
          {{ labels.BTN_AUTO_MATOME }}
        </v-btn>

        <v-btn
          v-if="role"
          color="success"
          :disabled="invoiceDetails.length === 0 || loading || invoiceStatus > 4"
          @click="showWeightInputModal"
        >
          {{ labels.BTN_WEIGHT_LIST_TORIKOMI }}
        </v-btn>

        <v-btn
          v-if="role"
          color="error"
          :disabled="selectedBoxes.length === 0 || hasEdaban || invoiceStatus > 4"
          @click="cancelSelectedBoxes"
        >
          {{ labels.BTN_BOX_CANCEL }}
        </v-btn>

        <v-btn
          v-if="role"
          color="primary accent-3"
          :disabled="cancelledBoxes.length === 0 || hasEdaban || invoiceStatus > 4"
          @click="showCancelledBoxModal = true"
        >
          {{ labels.BTN_UNDO_BOX_CANCEL }}
        </v-btn>
      </v-card-actions>
    </v-row>
    <v-card-subtitle
      v-if="recoverInvoices && !hasMemberId && selectedInvoice"
      class="text-caption red--text pt-0 pb-1 mb-2"
      >{{ messages.NO_MEMBER_ID_ON_SIPS }}</v-card-subtitle
    >

    <v-row>
      <v-card-text class="d-sm-flex flex-wrap justify-center my-0 pt-0 align-center"
        >選択BOX数：{{ noOfSelectedBoxesInTable1.commaString() }}、<span
          v-if="user?.authority !== authority.center.auth"
          >合計充当率：<span
            :class="
              (totalAppropriationRateOfSelectedBoxesInTable1 > 0 &&
                totalAppropriationRateOfSelectedBoxesInTable1 < 15) ||
              totalAppropriationRateOfSelectedBoxesInTable1 > 100 ||
              (totalAppropriationRateOfSelectedBoxesInTable1 > 25 && totalAppropriationRateOfSelectedBoxesInTable1 < 60)
                ? 'red--text darken-1'
                : ''
            "
            >{{ totalAppropriationRateOfSelectedBoxesInTable1.commaString() }}</span
          ><span
            v-show="
              (totalAppropriationRateOfSelectedBoxesInTable1 > 0 &&
                totalAppropriationRateOfSelectedBoxesInTable1 < 15) ||
              totalAppropriationRateOfSelectedBoxesInTable1 > 100 ||
              (totalAppropriationRateOfSelectedBoxesInTable1 > 25 && totalAppropriationRateOfSelectedBoxesInTable1 < 60)
            "
            class="px-1 mb-1"
          >
            <tool-tip v-if="totalAppropriationRateOfSelectedBoxesInTable1 > 100" :message="messages.M0001"></tool-tip>
            <tool-tip
              v-else-if="
                totalAppropriationRateOfSelectedBoxesInTable1 < 15 ||
                (totalAppropriationRateOfSelectedBoxesInTable1 > 25 &&
                  totalAppropriationRateOfSelectedBoxesInTable1 < 60)
              "
              :message="messages.M0003"
            ></tool-tip>
          </span>
          、</span
        >{{ labels.SOUSUURYOU }}：{{ totalQtyOfSelectedBoxesInTable1.commaString() }}、{{ labels.KINGAKU }}：{{
          totalPriceOfSelectedBoxesInTable1.commaString('$')
        }}、選択箱タイプ：<span :class="selectedBoxTypesInTable1.length > 1 || mixed ? 'red--text darken-1' : ''"
          >{{ selectedBoxTypesInTable1?.join(', ') ?? ''
          }}<span
            v-show="selectedBoxTypesInTable1?.length > 0 && maruNum?.[boxType - 1] !== selectedBoxTypesInTable1?.join()"
            ><v-icon size="20">mdi-arrow-right-thin</v-icon>{{ maruNum?.[boxType - 1] }}</span
          > </span
        ><span v-show="selectedBoxTypesInTable1.length > 1 || mixed" class="px-1 mb-1">
          <tool-tip v-if="selectedBoxTypesInTable1.length > 1" :message="messages.M0002"></tool-tip>
          <tool-tip v-if="mixed" :message="messages.M0004"></tool-tip>
        </span>
      </v-card-text>
    </v-row>

    <base-table
      ref="table1"
      item-key="box_no"
      multi-sort
      show-select
      :headers="konpoMeisai.headers1"
      :items="invoiceDetails"
      :column-name="[
        'item.appropriation_rate',
        'item.memo',
        'item.bid_total_price',
        'item.quantity',
        //'item.carton_bid_total_price',
      ]"
      :loading="loading"
      :per-page="-1"
      @row-selected="rowSelected"
    >
      <template v-slot:[`item.appropriation_rate`]="{ item }">
        <span
          v-if="item.appropriation_rate > 0 && authority?.center?.auth !== user.authority"
          :class="item.error ? 'red--text' : ''"
          >{{ item.appropriation_rate }}</span
        >
        <span v-else-if="item.appropriation_rate > 0 && authority?.center?.auth === user.authority"></span>
      </template>

      <template v-slot:[`item.memo`]="{ item }">
        <td align="center">
          <span v-if="item.is_softbank || item.is_mini_box || item.is_with_outer_box || item.special_box_flag">{{
            [
              item.is_softbank ? 'Softbank' : null,
              item.is_mini_box ? '小箱' : null,
              item.is_with_outer_box ? '外箱付' : null,
              item.special_box_flag &&
              item.special_box_limit_quantity != NULL &&
              item.special_box_limit_quantity >= item.quantity
                ? item.special_box_limit_quantity + '台以下特箱'
                : null,
              item.special_box_flag && item.special_box_limit_quantity == NULL ? '特箱' : null,
            ]
              .filter((x) => x)
              .join('、')
          }}</span>
        </td>
      </template>

      <template v-slot:[`item.bid_total_price`]="{ item }">
        <td style="text-align: right">
          <span v-if="item.bid_total_price != null">{{ item.bid_total_price.commaString('$') }}</span>
        </td>
      </template>

      <template v-slot:[`item.quantity`]="{ item }">
        <td style="text-align: right">
          <span v-if="item.quantity != null">{{ item.quantity.commaString() }}</span>
        </td>
      </template>
    </base-table>

    <v-container fluid class="pb-0">
      <v-row>
        <v-col sm="4">
          <v-card-actions class="pl-0">
            <v-select
              v-model="boxType"
              ref="boxType"
              class="pl-1 pr-0"
              outlined
              dense
              hide-details
              :label="boxTypeProperties.label"
              :items="boxTypes"
              required
            >
              <template v-slot:prepend-inner>
                <v-icon color="primary">mdi-package-variant</v-icon>
              </template>
              <template v-slot:selection="{ item }">
                <span>{{ item.value }}-{{ item.boxName }} {{ item.boxDim }}</span>
              </template>
              <template v-slot:item="{ item }">
                <span>{{ item.value }}-{{ item.boxName }} {{ item.boxDim }}</span>
              </template>
            </v-select>
          </v-card-actions>
        </v-col>
        <v-col sm="4" class="align-center">
          <v-card-actions class="justify-center">
            <v-btn outlined color="primary" @click="moveToTable1" :disabled="hasEdaban">
              <v-icon>mdi-package-up</v-icon>
            </v-btn>
            <v-btn outlined color="primary" @click="moveToTable2Manually" :disabled="hasEdaban">
              <v-icon>mdi-package-down</v-icon>
            </v-btn>
            <v-tooltip v-if="hasEdaban" top>
              <template v-slot:activator="{ on, attrs }">
                <v-avatar class="mx-2" size="20" v-bind="attrs" v-on="on">
                  <v-icon color="success"> mdi-help-circle </v-icon>
                </v-avatar>
              </template>
              <span v-html="messages.HAS_EDABAN"></span>
            </v-tooltip>
          </v-card-actions>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </v-container>

    <v-container fluid class="pt-0">
      <v-row justify="space-between" class="px-1 pb-1">
        <v-card-actions>
          <v-btn
            color="primary"
            @click="makeNewAnkenForSelectedBoxes"
            :disabled="packingDetails.length === 0 || loading || invoiceStatus > 4"
          >
            {{ labels.BTN_SENTAKU_CARTON_BETSU_ANKENKA }}
          </v-btn>
          <v-btn
            color="primary"
            @click="resetAnken"
            :disabled="packingDetails.length === 0 || loading || invoiceStatus > 4"
          >
            {{ labels.BTN_BUNKATSU_ANKEN_MODOSHI }}
          </v-btn>
          <v-tooltip v-if="invoiceStatus > 4" top>
            <template v-slot:activator="{ on, attrs }">
              <v-avatar class="mx-2" size="20" v-bind="attrs" v-on="on">
                <v-icon color="success"> mdi-help-circle </v-icon>
              </v-avatar>
            </template>
            <span v-html="messages.BRANCHING_LIMIT_BY_STATUS"></span>
          </v-tooltip>
        </v-card-actions>

        <v-card-actions>
          <v-card-text class="d-sm-flex flex-wrap justify-center my-0 align-center"
            >選択Carton数：{{ noOfSelectedCartonsInTable2 }}、{{ labels.GROSS_WEIGHT }}：{{
              totalWeightOfSelectedCartonsInTable2.commaString('kg')
            }}
            、{{ labels.SOUSUURYOU }}：{{ totalQtyOfSelectedCartonsInTable2.commaString() }} 、{{ labels.KINGAKU }}：{{
              totalPriceOfSelectedCartonsInTable2.commaString('$')
            }}
          </v-card-text>
        </v-card-actions>

        <v-card-actions>
          <v-btn
            outlined
            color="info"
            :disabled="packingDetails.length === 0"
            @click="
              downloadPackingExcels({
                packing_nos: packingDetails.map((row) => row.packing_no),
                download_packing_flag: true,
              })
            "
          >
            {{ labels.BTN_KONPO_MEISAI_DL }}
          </v-btn>
          <v-btn
            outlined
            color="info"
            :disabled="packingDetails.length === 0"
            @click="
              downloadPackingExcels({
                packing_nos: packingDetails.map((row) => row.packing_no),
                download_box_flag: true,
              })
            "
          >
            {{ labels.BTN_HAKO_MATOME_LIST_DL }}
          </v-btn>
        </v-card-actions>
      </v-row>
    </v-container>

    <base-table
      ref="table2"
      item-key="key"
      show-select
      :headers="konpoMeisai.headers2"
      :items="consolidatedBoxes"
      :column-name="[
        'item.model',
        'item.quantity',
        'item.memo',
        'item.box_weight',
        'item.gross_weight',
        'item.anken_total_weight',
        'item.bid_total_price',
        'item.anken_bid_total_price',
        'item.appropriation_rate',
        'item.carton_total_appropriation_rate',
        'item.carton_bid_total_price',
      ]"
      :per-page="-1"
      @row-selected="cartonSelected"
    >
      <template v-slot:[`item.model`]="{ item }">
        <td style="center">
          <span v-if="item.other_part_item === 1">{{ `${item.model}\n${otherPartsDescription}` }}</span>
          <span v-else>{{ item.model }}</span>
        </td>
      </template>
      <template v-slot:[`item.quantity`]="{ item }">
        <td style="text-align: right">
          <span v-if="item.quantity != null">{{ item.quantity.commaString() }}</span>
        </td>
      </template>

      <template v-slot:[`item.memo`]="{ item }">
        <td align="center">
          <span v-if="item.is_softbank || item.is_mini_box || item.is_with_outer_box || item.special_box_flag"
            >{{
              [
                item.is_softbank ? 'Softbank' : null,
                item.is_mini_box ? '小箱' : null,
                item.is_with_outer_box ? '外箱付' : null,
                item.special_box_flag &&
                item.special_box_limit_quantity != NULL &&
                item.special_box_limit_quantity >= item.quantity
                  ? item.special_box_limit_quantity + '台以下特箱'
                  : null,
                item.special_box_flag && item.special_box_limit_quantity == NULL ? '特箱' : null,
              ]
                .filter((x) => x)
                .join('、')
            }}
          </span>
        </td>
      </template>

      <template v-slot:[`item.box_weight`]="{ item }">
        <td style="text-align: right">
          <span v-if="item.box_weight != null">{{ item.box_weight.toFixed(1).commaString(' kg') }}</span>
        </td>
      </template>

      <template v-slot:[`item.gross_weight`]="{ item }">
        <td style="text-align: right">
          <span v-if="item.gross_weight != null">{{ item.gross_weight.toFixed(1).commaString(' kg') }}</span>
        </td>
      </template>
      <template v-slot:[`item.anken_total_weight`]="{ item }">
        <td style="text-align: right">
          <span v-if="item.anken_total_weight != null">{{
            item.anken_total_weight.toFixed(1).commaString(' kg')
          }}</span>
        </td>
      </template>

      <template v-slot:[`item.bid_total_price`]="{ item }">
        <td style="text-align: right">
          <span v-if="item.bid_total_price != null">{{ item.bid_total_price.commaString('$') }}</span>
        </td>
      </template>

      <template v-slot:[`item.anken_bid_total_price`]="{ item }">
        <td style="text-align: right">
          <span v-if="item.anken_bid_total_price != null">{{ item.anken_bid_total_price.commaString('$') }}</span>
        </td>
      </template>

      <template v-slot:[`item.appropriation_rate`]="{ item }">
        <span
          v-if="item.appropriation_rate > 0 && authority?.center?.auth !== user.authority"
          :class="item.error && typeof item.appropriation_rate === 'string' ? 'red--text' : ''"
          >{{ item.appropriation_rate }}
        </span>
        <span v-else-if="item.appropriation_rate > 0 && authority?.center?.auth === user.authority"></span>
        <span v-if="!item.appropriation_rate" class="red--text">{{ messages.NO_DEVICE_INFO_IN_PACKING_MASTER }}</span>
      </template>

      <template v-slot:[`item.carton_total_appropriation_rate`]="{ item }">
        <span v-if="item.carton_total_appropriation_rate > 0 && authority?.center?.auth != user.authority"
          >{{ item.carton_total_appropriation_rate }}
          <v-avatar v-if="item.carton_total_appropriation_rate > 100" size="20">
            <v-icon color="red darken-1"> mdi-alert-circle </v-icon>
          </v-avatar>
        </span>
        <span v-else-if="item.carton_total_appropriation_rate > 0 && authority?.center?.auth === user.authority"></span>
        <span v-if="!item.carton_total_appropriation_rate">-</span>
      </template>
      <template v-slot:[`item.carton_bid_total_price`]="{ item }">
        <span v-if="item.carton_bid_total_price > 0"
          >{{ item.carton_bid_total_price.commaString('$') }}
          <v-avatar v-if="item.carton_bid_total_price > 50000" size="20">
            <v-icon color="red darken-1"> mdi-alert-circle </v-icon>
          </v-avatar>
        </span>
      </template>
    </base-table>

    <v-card-actions class="justify-end pt-0">
      <v-btn v-if="role" color="primary" :disabled="disableSaveBtn" @click.stop="showModal">
        {{ labels.BTN_HENKO_HOZON }}
      </v-btn>
    </v-card-actions>

    <UploadInvoiceModal :parent="$data" @registered="getInvoiceDetails" />
    <UploadWeightModal :parent="$data" @uploaded="weightInput" />
    <DangerConfirmModal
      :show-modal="showDangerModal"
      :message="messages.ANKEN_CANCEL_CONFIRM"
      :confirmText="selectedInvoice"
      @click:confirm="handleDangerConfirm"
      @click:close="handleCloseDangerModal"
    />
    <CancelledBoxModal
      :show-modal="showCancelledBoxModal"
      :cancelledBoxes="cancelledBoxes"
      @click:confirm="handleCancelledBoxModal"
      @click:close="handleCloseCancelledBoxModal"
    />
  </v-card>
</template>

<script>
import { maruNum } from '@/consts.js';
import { mapActions, mapGetters } from 'vuex';
export default {
  components: {
    UploadInvoiceModal: () =>
      import(/* webpackChunkName: "UploadInvoiceModal" */ '@/components/ui/UploadInvoiceModal.vue'),
    UploadWeightModal: () =>
      import(/* webpackChunkName: "UploadWeightModal" */ '@/components/ui/UploadWeightModal.vue'),
    DangerConfirmModal: () =>
      import(/* webpackChunkName: "DangerConfirmModal" */ '@/components/ui/DangerConfirmModal.vue'),
    CancelledBoxModal: () =>
      import(/* webpackChunkName: "CancelledBoxModal" */ '@/components/ui/CancelledBoxModal.vue'),
    ToolTip: () => import(/* webpackChunkName: "ToolTip" */ '@/components/ui/ToolTip.vue'),
  },
  name: 'KonpoMeisai',
  data() {
    return {
      selectedInvoice: null,
      hakomatomezumi: this.$route.params.id || this.$route.params.invoiceNo ? true : false,
      consolidatedBoxes: [],
      selectedBoxes: [],
      noOfSelectedBoxes: 0,
      ankenNo: 1,
      cartonNo: 1,
      ankenCartonList: {},
      autoIncreaseCartonNo: false,
      merge: false,
      boxType: 1,
      uploadInvoiceShowModal: false,
      uploadWeightShowModal: false,
      clickedWeightInput: false,
      totalPackageMaterialsWeight: 0,
      role: false,
      showDangerModal: false,
      showCancelledBoxModal: false,
      recoverInvoices: false,
    };
  },
  computed: {
    ...mapGetters({
      otherPartsDescription: 'constantMaster/otherPartsDescription',
      boxTypes: 'konpomeisai/boxTypes',
      invoices: 'konpomeisai/invoiceLists',
      /**
       * t_invoiceのAPIレスポンス
       */
      invoiceDetails: 'konpomeisai/invoiceDetails',
      /**
       * t_packingのAPIレスポンス
       */
      packingDetails: 'konpomeisai/packingDetails',
      konpoMeisai: 'defined/konpoMeisai',
      labels: 'defined/labels',
      messages: 'defined/messages',
      clickedConfirm: 'ui/clickedConfirm',
      loading: 'ui/loading',
      uploading: 'ui/uploadLoading',
      path: 'defined/pathNames',
      user: 'defined/user',
      authority: 'defined/authority',
    }),
    buyerName() {
      return this.getInvoiceInfo('buyerName');
    },
    invoiceId() {
      return this.getInvoiceInfo('invoiceId');
    },
    matterNo() {
      return this.getInvoiceInfo('matterNo');
    },
    memberId() {
      return this.getInvoiceInfo('memberId');
    },
    boxTypeProperties() {
      const properties =
        this.boxTypes.length > 0
          ? {
              label: this.boxTypes.find((boxType) => boxType.value == this.boxType)?.boxName ?? '',
              dimension: this.boxTypes.find((boxType) => boxType.value == this.boxType)?.boxTypeDim ?? '',
            }
          : '';
      return properties;
    },
    status() {
      const remainingBoxesInTable1 = this.invoiceDetails.filter(({ delete_flag }) => delete_flag !== 1);
      return remainingBoxesInTable1.length === 0 ? 2 : 0;
    },
    invoiceStatus() {
      return +this.invoices
        .map(({ status, matterNo }) => (matterNo === this.selectedInvoice ? status : 0))
        .filter((x) => x)
        .join();
    },
    table2Headers() {
      const table2Rows = document.querySelectorAll('table')[1].rows;
      return [...table2Rows[0].cells];
    },
    cancelledBoxes() {
      return this.invoiceDetails
        .map(({ delete_flag, box_no }) => (delete_flag === 1 ? { box_no: box_no } : null))
        .filter((x) => x);
    },
    noOfSelectedBoxesInTable1() {
      return this.selectedBoxes.filter(({ anken_no }) => !anken_no).length;
    },
    noOfSelectedCartonsInTable2() {
      const table2Selected = this.$refs?.table2?.itemSelected?.length;
      if (table2Selected === 0) return 0;
      else return [...new Set(this.selectedBoxes.map(({ carton_no }) => carton_no))].length;
    },
    totalAppropriationRateOfSelectedBoxesInTable1() {
      return this.selectedBoxes
        .filter(({ anken_no }) => !anken_no)
        .reduce(
          (a, b) => (typeof b.appropriation_rate !== 'string' ? a + b.appropriation_rate : b.appropriation_rate),
          0,
        );
    },
    totalWeightOfSelectedCartonsInTable2() {
      const table2Selected = this.$refs?.table2?.itemSelected?.length;
      if (table2Selected === 0) return 0;

      const selectedCartons = [...new Set(this.selectedBoxes.map(({ carton_no }) => carton_no))];

      const total = selectedCartons
        .map((no) => {
          return [
            ...new Set(this.selectedBoxes.map(({ carton_no, gross_weight }) => (carton_no === no ? gross_weight : 0))),
          ];
        })
        .flat(Infinity)
        .reduce((a, b) => a + b, 0);

      return +total.toFixed(1);
    },
    totalPriceOfSelectedBoxesInTable1() {
      return this.selectedBoxes
        .filter(({ anken_no }) => !anken_no)
        .reduce((a, b) => (typeof b.bid_total_price !== 'string' ? a + b.bid_total_price : b.bid_total_price), 0);
    },
    totalPriceOfSelectedCartonsInTable2() {
      const table2Selected = this.$refs?.table2?.itemSelected?.length;
      if (table2Selected === 0) return 0;

      const selectedCartons = [...new Set(this.selectedBoxes.map(({ carton_no }) => carton_no))];

      return this.selectedBoxes
        .map(({ carton_no, bid_total_price }) => {
          return selectedCartons.map((no) => (carton_no === no ? bid_total_price : null)).filter((x) => x);
        })
        .flat(Infinity)
        .reduce((a, b) => a + b, 0);
    },
    totalQtyOfSelectedBoxesInTable1() {
      return this.selectedBoxes
        .filter(({ anken_no }) => !anken_no)
        .reduce((a, b) => (typeof b.quantity !== 'string' ? a + b.quantity : b.quantity), 0);
    },
    totalQtyOfSelectedCartonsInTable2() {
      const table2Selected = this.$refs?.table2?.itemSelected?.length;
      if (table2Selected === 0) return 0;
      const selectedCartons = [...new Set(this.selectedBoxes.map(({ carton_no }) => carton_no))];
      return this.selectedBoxes
        .map(({ carton_no, quantity }) => {
          return selectedCartons.map((no) => (carton_no === no ? quantity : null)).filter((x) => x);
        })
        .flat(Infinity)
        .reduce((a, b) => a + b, 0);
    },
    /**
     * 枝番が付いているかチェック
     */
    hasEdaban() {
      return this.selectedInvoice?.length > 14 ?? false;
    },
    /**
     * 上枠に選択されたボックスを下枠に移動する時にカートン箱タイプを自動で選択するために箱タイプを予想する。
     * - 例：上枠に箱タイプ２のみのボックスを複数選択して下枠に移動すると自動でカートン箱タイプ②を選択して下枠に移動する。
     * - 例：上枠に箱タイプを混ぜて選択された場合（⑦x５、①x３、②x１など）総台数が >=15 AND <=25の場合は⑦。それ以外の総台数の場合は一番先に選択された箱タイプ（例：⑦）が選択される。その場合は手動で正しいカートン箱タイプを選択する。
     * @return {number[]}
     */
    boxTypeOfSelectedBoxesInTable1() {
      return [...new Set(this.selectedBoxes.filter(({ anken_no }) => !anken_no).map(({ box_type }) => box_type))];
    },
    /**
     * 上枠に選択された箱タイプを表示
     * @return {number}
     */
    selectedBoxTypesInTable1() {
      return this.boxTypeOfSelectedBoxesInTable1?.map((b) => maruNum[b - 1])?.sort() ?? 1;
    },
    /**
     * 上枠に選択されたBOXの小箱数
     * @return {number}
     */
    noOfMiniBoxesInSelectedBoxesInTable1() {
      return (
        this.selectedBoxes
          .filter(({ anken_no }) => !anken_no)
          .map(({ is_mini_box }) => is_mini_box)
          .filter((x) => x).length ?? 0
      );
    },
    maruNum() {
      return maruNum;
    },
    /**
     * iPadとiPhoneが一つのカートンに混ぜている
     */
    mixed() {
      return (
        this.selectedBoxes.map(({ model }) => String(model).toLowerCase().includes('ipad')).includes(true) &&
        this.selectedBoxes.map(({ model }) => String(model).toLowerCase().includes('iphone')).includes(true)
      );
    },
    /**
     * SIPSの会員マスタにmemberが登録されているか？
     * GBSの会員マスタに登録されてSIPS側にはまだ登録されてないか確認する。
     * インボイス回復するためにはSIPS側の会員マスタにも会員情報が登録されてないとインボイス回復ができないため、事前チェックのため。
     */
    hasMemberId() {
      return !!this.invoices.find(({ matterNo }) => matterNo === this.selectedInvoice)?.sipsMemberId;
    },
    disableSaveBtn() {
      if (this.recoverInvoices && !this.hasMemberId) {
        return true;
      } else return this.uploading || this.invoiceStatus > 4;
    },
    disableAutoConsolidateBtn() {
      if (this.recoverInvoices && !this.hasMemberId) {
        return true;
      } else
        return (
          this.invoiceDetails.length === 0 ||
          this.loading ||
          this.invoiceStatus > 4 ||
          this.consolidatedBoxes.length > 0
        );
    },
  },
  watch: {
    /**
     * 上枠に選択されたボックスタイプを下枠に移動する時にカートン箱タイプを自動で設定する。
     */
    boxTypeOfSelectedBoxesInTable1(boxTypes) {
      /**
       * iPad、TabletとPCは小箱に入れない。それ以外は中途半端な充当率であれば小箱に入れる。
       * @type {boolean}
       */
      const doNotInsertToSmallBox = this.selectedBoxes
        .map(
          ({ model, category, is_pc }) =>
            String(model).toLowerCase().includes('ipad') ||
            is_pc === 1 ||
            String(category).toLowerCase().includes('tablet'),
        )
        .includes(true);
      if (!doNotInsertToSmallBox) {
        if (this.totalAppropriationRateOfSelectedBoxesInTable1 <= 25 && this.noOfMiniBoxesInSelectedBoxesInTable1 >= 0)
          this.boxType = 7;
        else if (
          (this.noOfMiniBoxesInSelectedBoxesInTable1 === 2 &&
            this.totalAppropriationRateOfSelectedBoxesInTable1 > 50 &&
            this.totalAppropriationRateOfSelectedBoxesInTable1 <= 75) ||
          (this.noOfMiniBoxesInSelectedBoxesInTable1 === 3 && this.totalAppropriationRateOfSelectedBoxesInTable1 <= 75)
        )
          this.boxType = 8;
        else if (
          (this.noOfMiniBoxesInSelectedBoxesInTable1 === 3 &&
            this.totalAppropriationRateOfSelectedBoxesInTable1 > 75 &&
            this.totalAppropriationRateOfSelectedBoxesInTable1 <= 100) ||
          (this.noOfMiniBoxesInSelectedBoxesInTable1 === 4 && this.totalAppropriationRateOfSelectedBoxesInTable1 <= 100)
        )
          this.boxType = 9;
        else this.boxType = boxTypes[0];
      } else if (boxTypes.length >= 1) {
        this.boxType = boxTypes[0];
      }
    },
    /**
     * 元々梱包されて保存されたバックス（DBからレスポンス）を下枠に移動して表示する。
     */
    packingDetails() {
      this.consolidatedBoxes = this.packingDetails;
    },
    /**
     * インボイスプルダウンにインボイスが変更された場合テーブルをクリアする。
     */
    invoiceId(val) {
      if (!val) {
        this.resetTables();
      }
    },
    /**
     * インボイスプルダウンにインボイスが変更された場合テーブルをクリアする。
     */
    matterNo(val) {
      if (!val) {
        this.resetTables();
      }
    },
    /**
     * インボイスプルダウンにインボイスが変更された場合テーブルをクリアする。
     */
    selectedInvoice(val) {
      if (!val) {
        this.resetTables();
      }
    },
    ankenNo(cur, old) {
      if (cur !== old) {
        this.cartonNo = 1;
      }
    },
    /**
     * 変更確認モーダルに「変更」ボタンを押した時のみ保存する。
     */
    clickedConfirm(value) {
      if (value) {
        this.henkoHozon();
      }
    },
    /**
     * インボイスプルダウンに箱まとめ済チェックを入れた場合まとめ済のインボイスリストを取得する。
     */
    hakomatomezumi(val) {
      this.apiGetInvoiceLists(val);
    },
    /**
     * GBS→SIPS連携時に失敗した案件を取得する。
     */
    recoverInvoices(val) {
      this.selectedInvoice = null;
      val ? this.apiGetFailedInvoiceLists() : this.apiGetInvoiceLists();
    },
    /**
     * 下枠のテーブルを案件、カートンごとでテーブル内にエクセルのように充当率、台数、金額をマージする。
     */
    merge(value) {
      if (value) {
        setImmediate(
          function () {
            const rows = this.getRows();
            this.setIdsToCells(rows);
            const ankenNos = Object.keys(this.ankenCartonList);
            ankenNos.forEach((ankenNo) => {
              this.ankenCartonList[ankenNo].forEach((cartonNo) => {
                this.mergeCells(cartonNo, ankenNo);
              });
            });
            this.merge = false;
            this.$refs.table2.resetSelected();
          }.bind(this),
        );
      }
    },
  },
  async created() {
    this.role = await this.getRole({
      page: this.path.KONPOMEISAI,
      authority: this.user.authority,
    });
    await this.initialize();
  },
  updated() {
    const disabled = document.querySelectorAll('.v-simple-checkbox--disabled');
    disabled.forEach((el) => {
      el.parentElement.parentElement.classList.add('text-decoration-line-through');
      el.parentElement.parentElement.classList.add('red--text');
    });
  },
  beforeDestroy() {
    this.resetTables();
  },
  methods: {
    ...mapActions({
      apiGetFailedInvoiceLists: 'api/getFailedInvoiceLists',
      /**
       * APIでt_invoiceから削除されてない案件リストを取得
       */
      apiGetInvoiceLists: 'api/getInvoiceLists',
      /**
       * APIでt_invoiceから選択された案件の詳細を取得する。取得されたデータは梱包されてない分上枠に表示されます。
       */
      apiGetInvoiceDetails: 'api/getInvoiceDetails',
      /**
       * APIでt_packingら選択された案件の詳細を取得する。取得されたデータは梱包された分下枠に表示されます。
       */
      apiGetPackingDetails: 'api/getPackingDetails',
      /**
       * 箱タイプ取得
       */
      apiGetBoxTypes: 'api/getBoxTypes',
      /**
       * APIでt_packingに梱包詳細を保存する。
       */
      apiUploadPackingDetails: 'api/uploadPackingDetails',
      /**
       * APIで自動まとめ処理を実行する。
       */
      apiAutoConsolidateBoxes: 'api/autoConsolidateBoxes',
      apiCancelAnken: 'api/cancelAnken',
      apiCancelBox: 'api/cancelBox',
      apiUndoCancelBox: 'api/undoCancelBox',
      resetInvoiceDetails: 'konpomeisai/resetInvoiceDetails',
      resetPackingDetails: 'konpomeisai/resetPackingDetails',
      setTable: 'konpomeisai/setKonpomeisai',
      toggleShowModal: 'ui/toggleShowModal',
      setClickedConfirmFlag: 'ui/setClickedConfirmFlag',
      setModalTitle: 'ui/setModalTitle',
      setModalMessage: 'ui/setModalMessage',
      setModalSuccessBtnLabel: 'ui/setModalSuccessBtnLabel',
      setModalConfirmBtnLabel: 'ui/setModalConfirmBtnLabel',
      resetModalContents: 'ui/resetModalContents',
      setUploadLoadingStatus: 'ui/setUploadLoadingStatus',
      setSuccess: 'ui/setSuccess',
      downloadPackingExcels: 'api/downloadPackingExcels',
      parseCsv: 'common/parseCsv',
      getRole: 'common/getRole',
      setCustomErrorMessage: 'setCustomErrorMessage',
      apiGetConstantList: 'api/getConstantList',
      apiDownloadRemakeCSV: 'api/downloadRemakeCSV',
    }),

    async initialize() {
      await Promise.all([
        this.apiGetInvoiceLists(this.$route.params.invoiceNo ? true : this.hakomatomezumi),
        this.apiGetBoxTypes(),
        this.apiGetConstantList(['GRADE_MODEL_NAME']),
      ]);
      if (this.$route.params.invoiceNo) {
        this.selectedInvoice = this.$route.params.invoiceNo;
        await this.getInvoiceDetails(this.selectedInvoice);
        if (!this.matterNo) this.showErrorModal();
      }
    },

    async downloadRemakeCSV() {
      await this.apiDownloadRemakeCSV({
        invoiceId: this.invoiceId,
        invoiceNo: this.matterNo,
      });
    },

    async getInvoiceDetails(matterNo, consolidatedBoxes = [], reRenderOnly = false) {
      if (!reRenderOnly) {
        this.resetTables();
        this.selectedInvoice = matterNo;
        if (this.invoiceId) await this.apiGetInvoiceDetails(this.invoiceId);
        if (this.matterNo) await this.apiGetPackingDetails(this.matterNo);
      } else {
        this.consolidatedBoxes = consolidatedBoxes;
      }
      if (this.consolidatedBoxes.length > 0) {
        this.reRenderTables();
      }
    },

    showUploadInvoiceModal() {
      this.uploadInvoiceShowModal = true;
    },

    showWeightInputModal() {
      this.uploadWeightShowModal = true;
    },

    weightInput(csvResult) {
      const foundBoxes = [];
      this.invoiceDetails.forEach((box) => {
        const packagingMaterialsWeight = this.boxTypes
          .sort((a, b) => a.value - b.value)
          .map(({ packaging_materials_weight }) => packaging_materials_weight);
        const noOfSameCartonBoxes = {};
        csvResult
          .map(({ carton_no, carton_box_type }) => ({
            carton_no,
            carton_box_type,
          }))
          .forEach((x) => {
            noOfSameCartonBoxes[x.carton_no] = {
              [x.carton_box_type]: (noOfSameCartonBoxes[x.carton_no]?.[x.carton_box_type] || 0) + 1,
            };
          });
        let found = csvResult.find(({ box_no }) => box_no === box.box_no);
        if (found) {
          const avgBoxWt =
            (+found.gross_weight - packagingMaterialsWeight[found.carton_box_type - 1]) /
            noOfSameCartonBoxes[found.carton_no][found.carton_box_type];
          box.box_weight = avgBoxWt;
          box.carton_no = found.carton_no;
          box.carton_box_type = found.carton_box_type;
          box.anken_no = 1;
          box.other_part_item = found.other_part_item;
          foundBoxes.push(box);
        }
      });
      this.consolidatedBoxes = [...this.consolidatedBoxes, foundBoxes].flat().sort((a, b) => a.carton_no - b.carton_no);
      if (this.consolidatedBoxes.length > 0) {
        this.reRenderTables();
      }
    },

    async autoConsolidateBoxes() {
      const temp = [...this.consolidatedBoxes];
      const largestCartonNoInAreadyConsolidatedBoxes = this.consolidatedBoxes.map(({ carton_no }) => carton_no).sort()[
        this.consolidatedBoxes.length - 1
      ];
      const consolidatedBoxes = await this.apiAutoConsolidateBoxes({
        invoiceId: this.invoiceId,
        dataSource: 2,
      });
      const mappedConsolidatedBoxes = consolidatedBoxes.map((box) =>
        largestCartonNoInAreadyConsolidatedBoxes > 1
          ? {
              ...box,
              carton_no: (box.carton_no += largestCartonNoInAreadyConsolidatedBoxes),
            }
          : box,
      );

      this.consolidatedBoxes = [...temp, mappedConsolidatedBoxes].flat().sort((a, b) => a.carton_no - b.carton_no);

      if (this.consolidatedBoxes.length > 0) {
        this.reRenderTables();
      }
    },

    reRenderTables() {
      const consolidatedBoxNos = this.consolidatedBoxes.map((box) => {
        return box.box_no;
      });

      let boxesInTable1 = this.invoiceDetails;
      for (const boxNo of consolidatedBoxNos) {
        boxesInTable1 = boxesInTable1.filter((box) => box.box_no !== boxNo);
      }
      this.setTable(boxesInTable1);

      const tempConsolidatedBoxes = [...this.consolidatedBoxes];
      this.recalculateConsolidatedBoxes(tempConsolidatedBoxes);
    },

    async uploadPackingDetails(deleteAll = false) {
      this.consolidatedBoxes.forEach((box) => {
        if (box.error && typeof box.appropriation_rate === 'string') {
          box.appropriation_rate = null;
        }
        if (!box.box_type) {
          box.box_type = 10;
        }
      });

      const authoName = Object.values(this.authority)?.find(({ auth }) => auth === this.user.authority)?.name ?? '';
      const payload = {
        consolidatedBoxes: this.consolidatedBoxes,
        invoiceId: this.invoiceId,
        invoiceNo: this.selectedInvoice,
        status: this.status,
        dataSource: 2,
        memberId: this.memberId,
        packingManualyUpdater: authoName ? authoName + this.user.userName : this.user.userName,
        deleteAll: deleteAll,
      };
      await this.apiUploadPackingDetails(payload);
      await this.apiGetPackingDetails(this.matterNo);
      this.reRenderTables();
    },

    async henkoHozon() {
      this.setUploadLoadingStatus(true);
      await this.uploadPackingDetails();
      this.setUploadLoadingStatus(false);
      this.setClickedConfirmFlag(false);
      this.setSuccess(true);
      this.setModalTitle('成功');
      this.setModalMessage('変更保存成功しました。');
      this.setModalSuccessBtnLabel('閉じる');
    },

    resetTables() {
      this.resetInvoiceDetails();
      this.resetPackingDetails();
      this.$refs.table1.resetSelected();
      this.$refs.table2.resetSelected();
      this.consolidatedBoxes = [];
      this.ankenNo = 1;
      this.cartonNo = 1;
      this.ankenCartonList = {};
    },

    showModal() {
      // if (this.consolidatedBoxes.length === 0) {
      //   return;
      // }
      this.toggleShowModal();
      this.setModalTitle('Info');
      this.setModalMessage('この内容でよろしいですか？');
      this.setModalConfirmBtnLabel(this.labels.BTN_HENKO_HOZON);
    },

    showErrorModal() {
      this.toggleShowModal();
      this.$store.commit(
        'ui/setCustomErrorMessage',
        !this.invoiceId ? this.messages.INVOICE_NOT_FOUND_ERROR : this.messages.SYSTEM_ERROR,
      );
    },

    saveEditDialog(value) {
      const box = value.item;
      this.consolidatedBoxes
        .filter((cb) => cb.anken_no == box.anken_no && cb.carton_no == box.carton_no)
        .forEach((filteredBox) => {
          filteredBox.carton_box_type = this.boxType;
          filteredBox.carton_box_type_lbl = this.boxTypeProperties.dimension;
        });
    },

    moveToTable1() {
      this.$refs.table2.resetSelected();
      this.consolidatedBoxes = this.consolidatedBoxes.filter((box) => this.selectedBoxes.indexOf(box) == -1);
      const boxesInTable1 = this.invoiceDetails;
      const boxNos = boxesInTable1.map((b) => b.box_no);
      this.selectedBoxes.forEach((selectedBox) => {
        if (!boxNos.includes(selectedBox.box_no)) {
          delete selectedBox.key;
          delete selectedBox.anken_no;
          delete selectedBox.carton_no;
          delete selectedBox.carton_box_type;
          delete selectedBox.carton_box_type_lbl;
          selectedBox.total_weight = selectedBox.box_weight;
          selectedBox.gross_weight = selectedBox.box_weight;
          boxesInTable1.push(selectedBox);
        }
      });
      this.setTable(boxesInTable1);
      this.$refs.table1.resetSelected();
      const tempConsolidatedBoxes = [...this.consolidatedBoxes];
      this.recalculateConsolidatedBoxes(tempConsolidatedBoxes);
    },

    moveToTable2() {
      this.noOfSelectedBoxes = this.selectedBoxes.length;
      if (this.selectedBoxes.length > 0) {
        this.moveBoxes(this.selectedBoxes);
        this.$refs.table1.resetSelected();
        const boxesInTable1 = this.invoiceDetails.filter((box) => this.selectedBoxes.indexOf(box) == -1);
        this.setTable(boxesInTable1);
      }
    },

    moveToTable2Manually() {
      if (Object.keys(this.ankenCartonList).length > 0) {
        this.ankenNo = parseInt(Object.keys(this.ankenCartonList).slice(-1)[0]);
        this.cartonNo = parseInt(this.ankenCartonList[this.ankenNo].slice(-1)) + 1;
      }
      this.moveToTable2();
      setImmediate(
        function () {
          if (Object.keys(this.ankenCartonList).length > 0) {
            this.ankenNo = parseInt(Object.keys(this.ankenCartonList).slice(-1)[0]);
            this.cartonNo = parseInt(this.ankenCartonList[this.ankenNo].slice(-1)) + 1;
          }
          this.adjustTable2();
          this.cartonNo++;
          this.ankenCartonList = {};
        }.bind(this),
      );
    },

    async cancelAnken() {
      if (!this.invoiceId) {
        this.showErrorModal;
        return;
      }
      await this.apiCancelAnken(this.invoiceId);
      await this.apiGetInvoiceLists();
      this.selectedInvoice = '';
    },

    async cancelSelectedBoxes() {
      if (this.selectedBoxes.length > 0) {
        this.setTable([]);
        await this.apiCancelBox({
          invoiceId: this.invoiceId,
          boxNos: this.selectedBoxes.map(({ box_no }) => box_no),
        });
        if (this.consolidatedBoxes.length !== this.packingDetails.length) {
          await this.uploadPackingDetails();
        }
        await this.getInvoiceDetails(this.selectedInvoice);
        this.$refs.table1.resetSelected();
        this.selectedBoxes = [];
      }
    },

    async undoCancelBoxes(boxNos) {
      if (this.invoiceId && boxNos.length > 0) {
        this.setTable([]);
        await this.apiUndoCancelBox({
          invoiceId: this.invoiceId,
          boxNos: boxNos,
        });
        await this.getInvoiceDetails(this.selectedInvoice);
        // this.$refs.table1.resetSelected();
      }
    },

    async handleDangerConfirm() {
      await this.cancelAnken();
      this.handleCloseDangerModal();
      window.location.reload();
    },

    async handleCancelledBoxModal(boxNos) {
      await this.undoCancelBoxes(boxNos);
    },

    makeNewAnkenForSelectedBoxes() {
      const temp = [...this.consolidatedBoxes];
      const tempConsolidatedBoxes = temp.filter((box) => this.selectedBoxes.indexOf(box) == -1);
      const newAnken = this.ankenNo + 1;
      this.selectedBoxes.forEach((box) => {
        box.carton_no = box.key;
        box.anken_no = newAnken;
        tempConsolidatedBoxes.push(box);
      });
      this.totalPackageMaterialsWeight = 0;
      this.recalculateConsolidatedBoxes(tempConsolidatedBoxes);
    },

    resetAnken() {
      const noOfAnkens = [...new Set(this.consolidatedBoxes.map(({ anken_no }) => anken_no))].length;
      if (noOfAnkens > 1) {
        const temp = [...this.consolidatedBoxes];
        this.ankenNo = 1;
        temp.forEach((box) => {
          box.carton_no = box.key;
          box.anken_no = this.ankenNo;
        });
        this.totalPackageMaterialsWeight = 0;
        this.recalculateConsolidatedBoxes(temp);
      } else if (this.consolidatedBoxes?.length === 0) {
        void 0;
      } else {
        this.uploadPackingDetails(true);
        // this.showDangerModal = true;
      }
    },

    recalculateConsolidatedBoxes(tempConsolidatedBoxes) {
      this.consolidatedBoxes = [];
      const ankens = [];
      const ankenNos = [...new Set(tempConsolidatedBoxes.map(({ anken_no }) => anken_no))];
      for (const ankenNo of ankenNos) {
        const anken = tempConsolidatedBoxes.filter(({ anken_no }) => anken_no == ankenNo);
        ankens.push(anken);
      }

      this.ankenNo = 1;
      this.cartonNo = 1;
      this.ankenCartonList = {};
      setImmediate(
        function () {
          ankens.forEach((anken) => {
            const cartons = [];
            const cartonNos = [...new Set(anken.map((box) => box.carton_no))];
            cartonNos.forEach((cartonNo) => {
              cartons.push(anken.filter((box) => box.carton_no === cartonNo));
            });
            cartons.forEach((carton) => {
              carton.forEach((box) => {
                box.anken_no = this.ankenNo;
                box.carton_no = this.cartonNo;
              });
              this.selectedBoxes = carton;
              this.moveToTable2();
              this.sumWeightsAndPricesInTable2Boxes();
              this.cartonNo++;
            });
            const _cartonNos = [...new Set(anken.map((box) => box.carton_no))];
            this.ankenCartonList[anken[0].anken_no] = _cartonNos;
            this.ankenNo++;
            this.cartonNo = 1;
            this.merge = true;
          });
          this.selectedBoxes = [];
          this.totalPackageMaterialsWeight = 0;
        }.bind(this),
      );
    },

    adjustTable2() {
      this.sumWeightsAndPricesInTable2Boxes();
      const rows = this.getRows(this.noOfSelectedBoxes);
      this.setIdsToCells(rows);
      this.mergeCells();
    },

    getRows(noOfSelectedBoxes) {
      // get table2 rows
      const htmlRowCollections = document.querySelectorAll('table')[1].rows;
      const rows = [...htmlRowCollections];
      rows[0].childNodes[1].style.border = 'none';
      // removes header row from htmlRowCollections
      rows.shift();
      const currentlySelectedRows = rows.slice(-noOfSelectedBoxes);
      return currentlySelectedRows;
    },

    getHeaderCellIndex(headerCell) {
      return this.table2Headers
        .map((header) => {
          return header.innerText === this.konpoMeisai.headers2.find((header) => header.value === headerCell).text;
        })
        .indexOf(true);
    },

    getInvoiceInfo(info) {
      return this.$route.params.id
        ? this.invoices?.find(
            ({ value, invoiceId }) => value === this.selectedInvoice && invoiceId === this.$route.params.id,
          )?.[info] ?? null
        : this.invoices?.find(({ value }) => value === this.selectedInvoice)?.[info] ?? null;
    },

    mergeCells(cartonNo, ankenNo) {
      const ankens = ankenNo
        ? document.querySelectorAll(`#anken-${ankenNo}`)
        : document.querySelectorAll(`#anken-${this.ankenNo}`);
      const cartons = cartonNo
        ? document.querySelectorAll(`#anken-${ankenNo}-carton-${cartonNo}`)
        : document.querySelectorAll(`#anken-${this.ankenNo}-carton-${this.cartonNo}`);
      const noOfAnkenColumns = 3;
      const noOfCartonColumns = 7;
      const ankensToMerge = [...ankens].slice(0, noOfAnkenColumns);
      const ankensToRemove = [...ankens].slice(noOfAnkenColumns);
      const cartonsToMerge = [...cartons].slice(0, noOfCartonColumns);
      const cartonsToRemove = [...cartons].slice(noOfCartonColumns);
      const _ankenNo = ankenNo ? ankenNo : this.ankenNo;
      const _cartonNo = cartonNo ? cartonNo : this.cartonNo;
      const ankenRowspan = this.consolidatedBoxes.filter(({ anken_no }) => anken_no == _ankenNo).length;
      const cartonRowspan = this.consolidatedBoxes.filter(
        ({ carton_no, anken_no }) => carton_no == _cartonNo && anken_no == _ankenNo,
      ).length;

      ankensToMerge.forEach((ankenCell) => ankenCell.setAttribute('rowspan', ankenRowspan));
      ankensToRemove.forEach((ankenCell) => ankenCell.remove());

      cartonsToMerge.forEach((cartonCell) => cartonCell.setAttribute('rowspan', cartonRowspan));
      cartonsToRemove.forEach((cartonCell) => cartonCell.remove());
    },

    setIdsToCells(rows) {
      rows.forEach((row) => {
        row.id = 'stop_hover';
        const cells = [...row.cells];
        const ankenCellIdx = this.getHeaderCellIndex('anken_no');
        const ankenTotalWeightCellIdx = this.getHeaderCellIndex('anken_total_weight');
        const ankenTotalBidPriceCellIdx = this.getHeaderCellIndex('anken_bid_total_price');
        const cartonCellIdx = this.getHeaderCellIndex('carton_no');
        const boxTypeCellIdx = this.getHeaderCellIndex('carton_box_type_lbl');
        const grossWeightCellIdx = this.getHeaderCellIndex('gross_weight');
        const totalQtyCellIdx = this.getHeaderCellIndex('total_qty');
        const cartonAppropriationRateCellIdx = this.getHeaderCellIndex('carton_total_appropriation_rate');
        const cartonTotalPriceCellIdx = this.getHeaderCellIndex('carton_bid_total_price');
        const remarkCellIdx = this.getHeaderCellIndex('memo');

        const ankenCartonCells = cells.filter((cell) => cell.cellIndex == 1);
        const checkboxCells = cells.filter((cell) => cell.id == '' && cell.cellIndex == 0);
        const mergeByAnkenCells = cells.filter(
          (cell) =>
            cell.id == '' &&
            (cell.cellIndex == ankenCellIdx ||
              cell.cellIndex == ankenTotalWeightCellIdx ||
              cell.cellIndex == ankenTotalBidPriceCellIdx),
        );
        const mergeByCartonCells = cells.filter(
          (cell) =>
            cell.id == '' &&
            (cell.cellIndex == cartonCellIdx ||
              cell.cellIndex == boxTypeCellIdx ||
              cell.cellIndex == grossWeightCellIdx ||
              cell.cellIndex == totalQtyCellIdx ||
              cell.cellIndex == cartonTotalPriceCellIdx ||
              cell.cellIndex == cartonAppropriationRateCellIdx),
        );
        const remarkCell = cells.filter((cell) => cell.id == '' && cell.cellIndex == remarkCellIdx);
        const ankenNo = mergeByAnkenCells[0] ? mergeByAnkenCells[0].innerText : this.ankenNo;
        const cartonNo = mergeByCartonCells[0] ? mergeByCartonCells[0].innerText : this.cartonNo;
        const ankenCarton = `anken-${ankenNo}-carton-${cartonNo}`;
        checkboxCells.forEach((cell) => (cell.id = `${ankenCarton}`));
        ankenCartonCells.forEach((cell) => {
          cell.id = `${ankenCarton}-keys`;
          cell.classList.add('px-0');
          cell.style.border = 'none';
          cell.style.minWidth = '0px';
          cell.innerText = '';
        });
        remarkCell.forEach((cell) => (cell.id = `${ankenCarton}-remark`));
        mergeByCartonCells.forEach((cell) => (cell.id = `${ankenCarton}`));
        mergeByAnkenCells.forEach((cell) => (cell.id = `anken-${ankenNo}`));
      });
    },

    moveBoxes(selectedBoxes, boxType) {
      boxType ? (this.boxType = boxType) : void 0;
      const boxNos = this.consolidatedBoxes.map((b) => b.box_no);
      selectedBoxes.forEach((box) => {
        if (!boxNos.includes(box.box_no)) {
          this.consolidatedBoxes.push({
            ...box,
            key: `${box.anken_no ? box.anken_no : this.ankenNo}-${box.carton_no ? box.carton_no : this.cartonNo}`,
            anken_no: box.anken_no ? box.anken_no : this.ankenNo,
            carton_no: box.carton_no ? box.carton_no : this.cartonNo,
            carton_box_type: box.carton_box_type ? box.carton_box_type : this.boxType,
            carton_box_type_lbl: box.carton_box_type_lbl
              ? box.carton_box_type_lbl
              : box.carton_box_type
                ? this.setBoxType(box.carton_box_type)
                : this.boxTypeProperties.dimension,
            // total_weight=anken_total_weight
            total_weight: box.box_weight,
            // gross_weight=carton_weight
            gross_weight: box.box_weight,
            box_type_lbl: maruNum[box.box_type - 1],
          });
          if (this.autoIncreaseCartonNo) {
            this.cartonNo++;
          }
        }
      });
    },

    setBoxType(boxType) {
      this.boxType = boxType;
      return this.boxTypeProperties.dimension;
    },

    sumWeightsAndPricesInTable2Boxes() {
      const packaging_materials_weight = this.boxTypes
        .sort((a, b) => a.value - b.value)
        .map(({ packaging_materials_weight }) => packaging_materials_weight);
      const sumAnken = (value) => {
        return value === 'gross_weight'
          ? this.consolidatedBoxes
              .filter((box) => box.carton_no === this.cartonNo && box.anken_no === this.ankenNo)
              .reduce((a, b) => a + b.total_weight, 0)
          : value === 'total_weight' || value === 'bid_total_price'
            ? this.consolidatedBoxes.filter((box) => box.anken_no === this.ankenNo).reduce((a, b) => a + b[value], 0)
            : value === 'appropriation_rate'
              ? this.consolidatedBoxes
                  .filter((box) => box.carton_no === this.cartonNo && box.anken_no === this.ankenNo)
                  .reduce((a, b) => a + b.appropriation_rate, 0)
              : value === 'quantity'
                ? this.consolidatedBoxes
                    .filter((box) => box.carton_no === this.cartonNo && box.anken_no === this.ankenNo)
                    .reduce((a, b) => a + b.quantity, 0)
                : value === 'carton_bid_total_price'
                  ? this.consolidatedBoxes
                      .filter((box) => box.carton_no === this.cartonNo && box.anken_no === this.ankenNo)
                      .reduce((a, b) => a + b.bid_total_price, 0)
                  : 0;
      };

      let totalPackageMaterialsWeight = 0;

      const uniqueCartons = [];
      [...new Set(this.consolidatedBoxes.map(({ carton_no }) => carton_no))].forEach((x) => {
        uniqueCartons.push([
          ...new Set(
            this.consolidatedBoxes
              .map(({ anken_no, carton_no, carton_box_type }) =>
                x === carton_no && anken_no === this.ankenNo ? carton_box_type : null,
              )
              .filter((x) => x),
          ),
        ]);
      });

      uniqueCartons.flat(Infinity).forEach((x) => {
        totalPackageMaterialsWeight += packaging_materials_weight[x - 1];
      });

      this.totalPackageMaterialsWeight = totalPackageMaterialsWeight;

      const ankenTotalWeight = sumAnken('total_weight') + this.totalPackageMaterialsWeight;

      const ankenBidTotalPrice = sumAnken('bid_total_price');

      const grossWeight = sumAnken('gross_weight');

      const totalQty = sumAnken('quantity');

      const cartonTotalAppropriationRate = sumAnken('appropriation_rate');

      const cartonTotalPrice = sumAnken('carton_bid_total_price');

      for (const box of this.consolidatedBoxes) {
        if (box.anken_no === this.ankenNo) {
          box.anken_total_weight = Number(ankenTotalWeight.toFixed(3));
          box.anken_bid_total_price = Number(ankenBidTotalPrice.toFixed(3));
        }
        if (box.carton_no === this.cartonNo && box.anken_no === this.ankenNo) {
          box.gross_weight = Number((grossWeight + packaging_materials_weight[box.carton_box_type - 1]).toFixed(3));
          box.carton_total_appropriation_rate =
            typeof cartonTotalAppropriationRate !== 'string' ? Number(cartonTotalAppropriationRate.toFixed(1)) : null;
          box.total_qty = totalQty;
          box.carton_bid_total_price = cartonTotalPrice;
        }
      }
    },

    rowSelected(selectedRows) {
      this.selectedBoxes = selectedRows;
    },

    cartonSelected(selectedCartons) {
      this.$refs.table1.resetSelected();
      if (selectedCartons.length == 0) {
        this.selectedBoxes.forEach((box) => {
          const ankenCells = document.querySelectorAll(`#anken-${box.anken_no}`);
          ankenCells.forEach((cell) => {
            cell.style.backgroundColor = '';
          });
        });
        this.$refs.table1.resetSelected();
        this.$refs.table2.resetSelected();
        this.selectedBoxes = [];
      } else {
        const temp = [];
        selectedCartons.forEach((carton) => {
          temp.push(
            this.consolidatedBoxes.filter(
              (box) => box.anken_no == carton.anken_no && box.carton_no == carton.carton_no,
            ),
          );
          const ankenCells = document.querySelectorAll(`#anken-${carton.anken_no}`);
          ankenCells.forEach((cell) => {
            cell.style.backgroundColor = this.$vuetify.theme.dark ? '#045ba2d6' : '#8acbffb5';
          });
        });
        this.selectedBoxes = temp.flat();
      }
    },
    handleCloseDangerModal() {
      this.showDangerModal = false;
    },
    handleCloseCancelledBoxModal() {
      this.showCancelledBoxModal = false;
    },
  },
};
</script>
